import { Expose, plainToClass, Transform } from 'class-transformer';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import {
    IsDate, IsEnum, IsNumber, Validate,
} from 'class-validator';
import SCAN_STATUS from '@/modules/markets/constants/scan-status.constant';
import MarketsCheckinDatesValidator from './validators/checkin-dates.validator';

export default class MarketsDocumentModel {
    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain._id)
    @IsNumber()
    id!: number;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return {};
        }

        const checkinDates = JSON.parse(JSON.stringify(plain.checkin_dates));

        Object.keys(checkinDates).forEach(day => {
            Object.keys(checkinDates[Number(day)]).forEach(hotelId => {
                if (hotelId === 'update_date' || hotelId === 'day_statistics') {
                    delete checkinDates[Number(day)][hotelId];
                    return;
                }

                checkinDates[Number(day)][hotelId] = plainToClass(
                    MarketsDocumentItemModel,
                    <MarketsDocumentItemModel> checkinDates[Number(day)][hotelId],
                    { excludeExtraneousValues: true },
                );
            });
        });

        return checkinDates;
    })
    @Validate(MarketsCheckinDatesValidator)
    checkinDates!: {
        [day: number]: {
            [hotelId: number]: MarketsDocumentItemModel,
        };
    };

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return {};
        }

        const dates = {} as Record<number, string>;

        Object.keys(plain.checkin_dates).forEach(day => {
            dates[Number(day)] = plain.checkin_dates[Number(day)].update_date || null;
        });

        return dates;
    })
    @Validate(MarketsCheckinDatesValidator)
    updateDates!: {
        [day: number]: string;
    };

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return undefined;
        }

        const dates = {} as Record<number, Record<string, number | null>>;

        Object.keys(plain.checkin_dates).forEach(day => {
            dates[Number(day)] = {
                demand: null,
                occupancy: null,
            };

            const { day_statistics: dayStatistics } = plain.checkin_dates[Number(day)];

            if (!dayStatistics) {
                return;
            }

            if (!Number.isFinite(dayStatistics.demand)) {
                dates[Number(day)].demand = null;
            } else if (dayStatistics.demand < 0) {
                dates[Number(day)].demand = 0;
            } else {
                dates[Number(day)].demand = Math.round(dayStatistics.demand * 100);
            }

            if (!Number.isFinite(dayStatistics.occupancy)) {
                dates[Number(day)].occupancy = null;
            } else if (dayStatistics.occupancy < 0) {
                dates[Number(day)].occupancy = 0;
            } else {
                dates[Number(day)].occupancy = Math.round(dayStatistics.occupancy);
            }
        });

        return dates;
    })
    dayStatistics?: {
        [day: number]: {
            demand: number | null;
            occupancy: number | null;
        };
    };

    @Expose()
    @Transform((_, plain) => Math.round(Number(plain.score)) || null)
    score!: number;

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    @IsDate()
    finishScanDate?: Date;

    @Expose()
    @Transform((_, plain) => plain.status || SCAN_STATUS.FINISHED)
    @IsEnum(SCAN_STATUS)
    scanStatus!: SCAN_STATUS;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => {
        const { pos, los, provider_name: provider } = plain;
        const { requested_number_of_guests: numberOfGuests } = plain;
        const { month, year } = plain;

        return {
            pos,
            los,
            provider,
            numberOfGuests,
            month,
            year,
        };
    })
    settings!: {
        pos: string;
        los: number;
        provider: string;
        numberOfGuests: number;
        month: number;
        year: number;
    };
}
