import { Inject, injectable } from 'inversify-props';
import { CoralogixPlugin } from '@/plugins/coralogix.plugin';

import UserModel from '@/modules/user/models/user.model';
import UserApiService, { UserApiServiceS } from '@/modules/user/user-api.service';
import HeadService, { HeadServiceS } from '@/modules/common/services/head.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import WalkmeService, { WalkmeServiceS } from '@/modules/walkme/walkme.service';
import _ from 'lodash';
import UserStore from './store/user.store';
import USER_LEVELS from './constants/user-levels.constant';
import USER_ROLES from './constants/user-roles.constant';
import UserSettings from './store/user-settings.store';
import WhatfixService, { WhatfixServiceS } from '../whatfix/whatfix.service';
import { DEFAULT_GRAPH_COLORS } from '../common/constants/default-graph-colors.constant';

declare global {
    interface Window {
        gtag: Function;
    }
}

export const UserServiceS = Symbol.for('UserServiceS');
@injectable(UserServiceS as unknown as string)
export default class UserService {
    @Inject(UserApiServiceS) private userApiService!: UserApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HeadServiceS) private headService!: HeadService;
    @Inject(WalkmeServiceS) private walkmeService!: WalkmeService;
    @Inject(WhatfixServiceS) private whatfixService!: WhatfixService;

    readonly storeState: UserStore = this.storeFacade.getState('UserStore');
    readonly settingsStoreState: UserSettings = this.storeFacade.getState('UserSettings');

    async initUser(token: string | null) {
        this.storeState.loading.start();

        if (token === null) {
            return;
        }

        this.storeState.user = await this.userApiService.getUser(token);

        if (this.storeState.user === null) {
            return;
        }

        window.gtag('set', 'user_id', this.user.id);

        this.headService.setFavicon(this.storeState.user.level);
        this.headService.setTitle(this.storeState.user.level);
        this.storeState.loading.finish();

        if (this.isWalkmeEnabled === true) {
            this.walkmeService.init();
        }

        this.whatfixService.init();
        CoralogixPlugin.updateUserContext(this.storeState.user);
    }

    get isUserLoaded() {
        return !!this.storeState.user;
    }

    // Always returns user. Use isUserLoaded to check is it loaded already
    get user(): UserModel {
        if (!this.storeState.user) {
            throw new Error('No user found');
        }
        return this.storeState.user;
    }

    get isNewUser() {
        return this.user
            && this.user.level === USER_LEVELS.ONBOARDING;
    }

    get currentHotelId(): number | null {
        return this.storeState.user?.currentHotelId || null;
    }

    set currentHotelId(value: number | null) {
        if (!this.user || !value) {
            return;
        }
        this.user.currentHotelId = value;
    }

    get enabledFeatures() {
        const isDebug = window.location.search.includes('debug=true')
            && process.env.NODE_ENV === 'development';

        if (isDebug) {
            // NOTE: can be used for testing
            return {
                home: true,
                rate: true,
                market: true,
                guest_review: true,
                events: true,
                deep_compset: true,
                lite_di: true,
                promotion_detection: true,
                targeted_insights: true,
            } as { [k: string]: boolean };
        }

        if (!this.user || !this.user.enabledFeatures) {
            return null;
        }

        return this.user.enabledFeatures;
    }

    get viewAs(): USER_LEVELS | null {
        if (!this.isUserLoaded) {
            return null;
        }

        return this.user.viewAs;
    }

    get isWalkmeEnabled() {
        if (this.user === null) {
            return false;
        }

        return this.user.isWalkmeEnabled;
    }

    get isLoadingAndInitialized() {
        return this.storeState.loading.isLoading() && this.storeState.loading.isInitialized;
    }

    // Don't use this method in components to switch between hotels.
    // Use goToHotel/Cluster from user-view.service
    async setViewAs(level: USER_LEVELS, id?: number) {
        if (!this.isUserLoaded) {
            await this.storeState.loading.whenLoadingFinished();
        }

        switch (level) {
            case USER_LEVELS.HOTEL:
                if (!id) {
                    throw new Error('No hotelId!');
                }

                if (this.user?.currentHotelId === id && this.user?.viewAs === USER_LEVELS.HOTEL) {
                    return;
                }

                this.storeState.user = {
                    ...this.user,
                    viewAs: USER_LEVELS.HOTEL,
                    currentHotelId: id,
                };
                break;

            case USER_LEVELS.CHAIN:
                this.storeState.user = {
                    ...this.user,
                    viewAs: USER_LEVELS.CHAIN,
                    currentHotelId: null,
                };
                break;
            case USER_LEVELS.CLUSTER:
                this.storeState.user = {
                    ...this.user,
                    viewAs: USER_LEVELS.CLUSTER,
                    currentHotelId: null,
                };
                break;

            default:
                throw new Error('Wrong user level!');
        }

        CoralogixPlugin.updateUserContext(this.storeState.user);
    }

    get currentCompany(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainName;
    }

    set currentCompany(value: string | null) {
        if (this.user && value) {
            this.user.chainName = value;
        }
    }

    get chainName(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainName;
    }

    get chainId(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainId;
    }

    get id(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.id;
    }

    get chainNumber(): string | null {
        if (!this.user) {
            return null;
        }
        return this.user.chainNumber || null;
    }

    get levelName() {
        if (!this.user) {
            return null;
        }
        return this.user.levelName;
    }

    get isHotelUser() {
        if (this.isCarUser) {
            return false;
        }

        return this.storeState.user
            && this.userBelongsTo([
                USER_LEVELS.HOTEL,
                USER_LEVELS.CLUSTER,
                USER_LEVELS.CHAIN,
                USER_LEVELS.ONBOARDING,
            ]);
    }

    get isClusterUser() {
        return this.storeState.user && this.user.level === USER_LEVELS.CLUSTER;
    }

    get isChainUser() {
        return this.storeState.user && this.user.level === USER_LEVELS.CHAIN;
    }

    get isChainOrClusterUser() {
        return this.storeState.user && (this.user.level === USER_LEVELS.CHAIN || this.user.level === USER_LEVELS.CLUSTER);
    }

    get isCarUser() {
        return this.storeState.user && this.user.level === USER_LEVELS.CAR;
    }

    get isDemoUser() {
        return /(dev)|(demo)|(localhost)/.test(window.location.host);
    }

    get isViewAsHotel() {
        return this.storeState.user && this.user.viewAs === USER_LEVELS.HOTEL;
    }

    get isViewAsChain() {
        return this.storeState.user && (this.user.viewAs === USER_LEVELS.CHAIN);
    }

    get isViewAsCluster() {
        return this.storeState.user && (this.user.viewAs === USER_LEVELS.CLUSTER);
    }

    get routeBranch() {
        const level = this.isCarUser
            ? USER_LEVELS.CAR
            : window.location.pathname.split('/')[1].split('-')[0];

        return level === 'onboarding'
            ? USER_LEVELS.ONBOARDING
            : level as USER_LEVELS;
    }

    get isAdmin() {
        return (this.storeState.user && this.user.role === USER_ROLES.ADMIN) || false;
    }

    // Is superadmin true, if user logged in via admin panel
    // In this case user is not a real admin
    get isSuperadmin() {
        return !!this.storeState.user && this.user.isSuperadmin;
    }

    get urlChainClusterParam() {
        if (this.isClusterUser) {
            return 'cluster';
        }

        return 'chain';
    }

    get userLevel(): 'chain' | 'cluster' | 'hotel' {
        if (this.isChainUser) {
            return 'chain';
        }

        if (this.isClusterUser) {
            return 'cluster';
        }

        return 'hotel';
    }

    get hasDI() {
        return !!this.storeState.user && this.user.applications.DI;
    }

    get isDemo() {
        return this.storeState.user ? !!this.user.demoId : false;
    }

    get isReadonly() {
        return _.get(this.user, 'isReadonly', false);
    }

    // Only in case to avoid circle dependency when can't import UserSettingsService
    get chartColors() {
        const userColors = Object.keys(this.user?.settings.colors || {}).length
            ? this.user?.settings.colors!
            : DEFAULT_GRAPH_COLORS;

        const { list } = this.settingsStoreState.chartColors;

        if (!list || !list.length) {
            return userColors;
        }

        return list;
    }

    userBelongsTo(requiredLevel: USER_LEVELS | USER_LEVELS[]) {
        const { userLevel, viewAs } = this;

        if (Array.isArray(requiredLevel)) {
            return requiredLevel.includes(userLevel as USER_LEVELS)
                || requiredLevel.includes(viewAs as USER_LEVELS);
        }

        return userLevel === requiredLevel
            || viewAs === requiredLevel;
    }
}
