/* eslint-disable camelcase */
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import UserModel from '@/modules/user/models/user.model';

import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import { map, wrap } from 'lodash';
import ConfigService, { ConfigServiceS } from '../config/config.service';

import CurrencyListModel from './models/currency-list.model';
import UserHotelAlertsModel from './models/user-hotel-alerts.model';
import { DefaultFilters } from './types';
import SpecialDateModel from './models/special-date.model';

export const UserApiServiceS = Symbol.for('UserApiServiceS');

@injectable(UserApiServiceS as unknown as string)
export default class UserApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ConfigServiceS) private configService!: ConfigService;

    async getUser(token: string): Promise<UserModel> {
        const fornovaAppPostfix = this.configService.fornovaApp ? `/${this.configService.fornovaApp}` : '';

        const { data } = await this.apiService.post(
            `users/sso${fornovaAppPostfix}`,
            { token },
            { headers: { 'Content-Type': 'application/json' } },
        );

        return plainToClass(UserModel, <UserModel> data, { excludeExtraneousValues: true });
    }

    async updateMainPos(compsetId: string, newMainPos: string) {
        const body = {
            main_pos: newMainPos,
            compset_ids: [compsetId],
        };

        return this.apiService.put('/compsets/update-main-pos', body);
    }

    updateSubscriptions({ htmlReports, scheduledReports }: UserAPI.Subscriptions) {
        return this.apiService.post('users/settings', {
            settings: {
                subscribedListForMail: {
                    'html-report': htmlReports,
                    'scheduled-excel': scheduledReports,
                },
            },
        });
    }

    updateCompsetSettings(newSettings: Partial<UserAPI.CompsetSettingsBody>, fornovaId: number) {
        return this.apiService.post(`users/compsets-settings/${fornovaId}`, {
            settings: {
                ...newSettings,
            },
        });
    }

    updateSettings(settings: Partial<UserAPI.Settings>) {
        return this.apiService.post('users/settings', { settings });
    }

    updateHotelSettings(settings: UserAPI.IUserHotelAlerts) {
        const fornovaId = Number(Object.keys(settings)[0]);
        const body = {
            fornovaId,
            alertsAndNotifications: {
                ...settings[fornovaId],
            },
        };
        return this.apiService.put('hotels/update-alert-setting', body);
    }

    async updateDisplayCurrency(currency: string | null) {
        const { data } = await this.apiService.post('users/settings', {
            settings: {
                currency,
            },
        });
        return data;
    }

    async updateChartColors(colors: string[]) {
        const { data } = await this.apiService.post('users/settings', {
            settings: {
                colors,
            },
        });
        return data;
    }

    async updateDefaultFilters(filters: DefaultFilters) {
        const {
            price, los, mealType,
            numberOfGuests, mapProvider,
            targetedInsights,
        } = filters;

        const body = {} as {
            price?: string;
            los?: number;
            number_of_guest?: number;
            meal_type?: string[];
            map_provider?: string;
            targeted_insights?: Record<string, boolean>;
        };

        if (price) {
            body.price = price;
        }

        if (los) {
            body.los = los;
        }

        if (numberOfGuests) {
            body.number_of_guest = numberOfGuests;
        }

        if (mapProvider) {
            body.map_provider = mapProvider;
        }

        if (targetedInsights) {
            body.targeted_insights = targetedInsights;
        }

        if (mealType) {
            if (mealType === 'any') {
                body.meal_type = [];
            } else {
                body.meal_type = [mealType];
            }
        }

        const res = await this.apiService.post('users/save-user-default-filters', body);
        return res;
    }

    async getCurrentCompsetSettings(fornovaId: number) {
        const res = await this.apiService.get(`users/compsets-settings/${fornovaId}`);

        return {
            settings: res.data.settings as UserAPI.CompsetSettingsBody,
            providers: res.data.rate_providers || [],
        };
    }

    async getAllCurrencies() {
        const { data } = await this.apiService.get('users/currencies');
        return plainToClass(CurrencyListModel, <CurrencyListModel> data, { excludeExtraneousValues: true });
    }

    async getHotelSettings(fornovaId: number) {
        const { data } = await this.apiService.get(`hotels/alerts-and-notifications-settings/${fornovaId}`);
        return plainToClass(UserHotelAlertsModel, <UserHotelAlertsModel> data, { excludeExtraneousValues: true });
    }

    async addSpecialDate(specialDate: SpecialDateModel) {
        const { data } = await this.apiService
            .post('users/settings/intraday-special-dates', {
                date_name: specialDate.name,
                start_date: specialDate.start,
                end_date: specialDate.end,
            });

        return plainToClass(SpecialDateModel, data.settings.intraday_special_dates as any[], { excludeExtraneousValues: true });
    }

    async deleteSpecialDate(specialDateId: string) {
        await this.apiService
            .delete(`users/settings/intraday-special-dates/${specialDateId}`);
    }

    async updateSpecialDate(specialDate: SpecialDateModel) {
        const { data } = await this.apiService
            .put(`users/settings/intraday-special-dates/${specialDate.id}`, {
                date_name: specialDate.name,
                start_date: specialDate.start,
                end_date: specialDate.end,
            });

        return plainToClass(SpecialDateModel, data.settings.intraday_special_dates as any[]);
    }
}
